.landing__main__text {
  font-family: "Glacial-Indifference-Bold";
  font-size: 3rem;
  text-align: left;
  width: 80vw;
  height: 25vh;
  padding-top: 3rem;
}

.text-primary-crbonScope {
  font-family: "Glacial-Indifference-Bold";
  font-size: 3rem;
  color: #8c1c56;
}

.crbonScope-header {
  background: linear-gradient(
    to bottom left,
    var(--pink),
    var(--salmon),
    var(--burgundy)
  );
  border-radius: 30px;
}

.text-white {
  color: white;
}

.typewriter-text {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.subtitle_text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
  width: 80vw;
  margin-left: 7.5rem;
}

.landing_introducing_text-grey {
  font-size: 1.5em;
  text-align: left;
  color: #999999;
}

.landing__button__container {
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: right;
  margin-left: auto;
}

@media (max-width: 1024px) {
  .landing__main__text {
    height: 30vh;
  }
}

@media (max-width: 768px) {
  .landing__main__text {
    height: 90vh;
  }
}

@media (max-width: 425px) {
  .landing__button__container {
    display: flex;
    align-content: center;
    justify-content: center;
    text-align: center;
    margin-right: auto;
  }

  .text-primary {
    font-size: 2rem;
  }
}
