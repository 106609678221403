.project__wrapper {
  font-family: "Glacial-Indifference-Regular";
  height: 89.7vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.project__header {
  margin-top: 5rem;
  text-align: start;
  width: 80%;
}

.search-and-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.project-table-container {
  width: 100%;
  height: 500px;
  overflow: auto;
}

.custom-grid-projects {
  width: 100%;
  height: 100%;
}

.custom-grid-projects .MuiDataGrid-columnHeaderCheckbox {
  background-color: rgba(255, 158, 166, 0.7);
}

.owned-project {
  color: blue;
  text-decoration: underline;
}

.unowned-project {
  color: grey;
  pointer-events: none;
  text-decoration: none;
}

.search-bar {
  width: 50rem;
}

.owned-button {
  background-color: #1976d2;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.owned-button:hover {
  background-color: #1565c0;
}

@media (max-width: 700px) {
  .project__wrapper {
    height: 100vh;
  }
}
