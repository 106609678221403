.dataGrid_columnHeader {
  background-color: rgba(255, 158, 166, 0.7);
  min-width: 100px;
}

.custom-grid .MuiDataGrid-columnHeader {
  min-width: 100px;
}

.custom-grid .MuiDataGrid-columnHeaderCheckbox {
  background-color: rgba(255, 158, 166, 0.7);
}

.custom-grid .MuiDataGrid-cell {
  min-width: 100px;
}

.userBoard__container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically aligns content within the container */
  width: 90%;
  margin: 0 auto; /* Horizontally centers the container */
}

.userBoard__content {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
}

.userBoard__inputsContainer {
  flex: 1; /* Take up available space */
  display: flex;
  flex-direction: column; /* Stack inputs vertically */
}

.dataGrid__container {
  flex: 2; /* Grid gets more space */
  display: flex;
  flex-direction: column;
  max-width: 60%;
}

.auth__button-container {
  display: flex;
  flex-direction: column; /* Stack button at the bottom */
  justify-content: center; /* Push the button to the bottom */
  align-items: center;
  width: 30%;
  min-width: 150px;
}

.user__form {
  display: flex;
  flex-direction: column; /* Maintain row layout */
  justify-content: space-between;
  align-items: flex-start; /* Align items to the top */
}

.userGrid__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 5rem;
}
.custom-grid-user {
  width: 100%;
}

.custom-grid-user .MuiDataGrid-columnHeaderCheckbox {
  background-color: rgba(255, 158, 166, 0.7);
}

.dataGrid__container-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.dataGrid__header {
  align-self: flex-start;
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 100%;
}

.dataGrid__buttonGroup {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.action__button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 10px;
  border: solid 1px rgba(153, 153, 153, 0.3);
  width: 6rem;
  height: 2.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease,
    color 0.3s ease;
  padding: 0 3.5rem;
}

.action__button:hover {
  background-color: rgba(153, 153, 153, 0.1);
  border-color: rgba(153, 153, 153, 0.5);
}

.action__button:active {
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(153, 153, 153, 0.7);
}

.editUser__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
  margin-bottom: 8rem;
}

@media (max-width: 768px) {
  .userBoard__content {
    flex-direction: column;
  }
  .dataGrid__container {
    max-width: 100%;
  }
  .user__form {
    gap: 1rem;
  }
  .auth__button-container {
    width: 100%;
  }
}
