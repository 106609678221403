.projectDetail_container {
  width: 70%;
  height: auto;
  margin: 10rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Glacial-Indifference-Regular";
}
.projectDetail_container h2 {
  font-family: "Glacial-Indifference-Bold";
  font-size: 1.8rem;
  margin-bottom: 10px;
}
.projectDetail_container p,
.projectDetail_container span {
  font-size: 1.2rem;
  margin: 0;
}

.projectDetail_back {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: min-content;
}
.projectDetail_back span {
  margin-left: 5px;
}

.image__section {
  display: flex;
  flex-direction: column;
}

.selected__image img {
  width: 100%;
  height: 25rem;
  object-fit: cover;
  border-radius: 10px;
}

.image__selection {
  margin-top: 1rem;
  width: 100%;

  border-radius: 10px;
}

.image__selection img {
  scale: 0.95 !important;
  width: 100%;
  height: 12rem;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.image__selection img:hover {
  transform: scale(1.03);
}

.project__fieldsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr; /* Ensures uniform row heights */
  gap: 15px;
  margin-top: 3rem;
}

.project__field {
  display: flex;
  flex-direction: column;
}

.location__section {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 50vh;
}

.file__section {
  position: relative;
  z-index: 2;
}

.document__btns {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 1rem;
}

.loading__cicle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

@media (max-width: 1440px) {
  .project__fieldsContainer {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for medium screens */
  }
}

@media (max-width: 1024px) {
  .project__fieldsContainer {
    grid-template-columns: repeat(2, 1fr); /* 3 columns for medium screens */
  }
}

@media (max-width: 768px) {
  .project__fieldsContainer {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for small screens */
  }
}

@media (max-width: 450px) {
  .project__fieldsContainer {
    grid-template-columns: repeat(1, 1fr); /* 2 columns for small screens */
  }
}
