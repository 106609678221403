.admin__container {
  font-family: "Glacial-Indifference-regular";
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.admin__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin-top: 10rem;
  margin-left: auto;
  margin-right: auto;
}
