.navbar-container {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background: white;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transition: top 0.6s;
  /* left: 50%;
    transform: translateX(-50%); */
}

.navbar-container:not(.scrolled) {
  background: none;
}

.navbar-content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 0 1rem;
}

.navbar-content-container.scrolled {
  padding: 0 2rem 0 2rem;
}

.screenreader-text {
  left: 0;
  position: absolute;
  top: -9999vh;
}

.navbar-menu-container {
  position: fixed;
  width: 100%;
  height: 0;
  overflow: hidden;
  z-index: 1000;

  font-family: "Glacial-Indifference-Bold";
  font-size: 1rem;
  background-image: linear-gradient(
    to bottom right,
    rgba(96, 9, 52, 0.99),
    rgba(140, 28, 86, 0.95),
    rgba(255, 122, 127, 0.95)
  );

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, height 0.3s;
  overflow: hidden;
}

.navbar-menu-container.open {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.navbar-menu-container > .navbar-container {
  background: none;
}

.navbar-menu-container > .crbonlabs-icon-menu {
  transform-origin: center left;
  width: 8.8em;
}

.menu-links-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  height: calc(80% - 100px);
  /* Adjust the height to accommodate the close button container */
  padding: 100px 20px 100px;
  font-size: 24px;
  min-height: 400px;
}

.navbar-links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Glacial-Indifference-Bold";
  font-size: 1.2rem;
}

/* Add margin to all children of the navbar-links-container div */
.navbar-links-container > * {
  padding: 0 2.5rem;
}

.navbar-link {
  color: black;
  text-transform: uppercase;
}

.navbar-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #232323;
  border-radius: 30px;
  height: 3rem;
  color: white;
  border: solid 1px #232323;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  min-width: 65px;
}

.navbar-login-button:hover {
  color: #232323;
  background-color: transparent;
}

.username__button {
  color: #8c1c56;
}

.user-menu {
  font-family: "Glacial-Indifference-Bold";
  margin-top: 0.1rem;
  margin-left: -1.5rem;
}

.navbar-link:hover {
  cursor: pointer;
}

.navbar-link-color-animation {
  transition: color 0.25s;
  -webkit-transition: color 0.25s;
  -moz-transition: color 0.25s;
  -o-transition: color 0.25s;
  -ms-transition: color 0.25s;
}

.navbar-link-color-animation:hover {
  color: var(--burgundy);
}

#navbar-logo {
  font-size: 3rem;
  padding: 0 0.5rem;
  font-family: "Lobster";
  font-weight: 800;
  color: black;
  background: linear-gradient(
    to right,
    var(--blue),
    var(--blue) 50%,
    black 50%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 0.35s ease;
}

#navbar-logo:hover {
  background-position: 0 100%;
  cursor: pointer;
}

.nav-icon-animation {
  transform: translate3d(50%, 50%, 0) scale(1);
  transition: transform 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  /* position: absolute; */
  transform-origin: center left;
  width: 40.48em;
  max-width: 90vw;
  will-change: transform;
  height: auto;
  /* left: 0; */
  position: absolute;
  top: 50%;
}

.scrolled .nav-icon-animation {
  transition: transform 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  transform-origin: center left;
  width: 40.48em;
  will-change: transform;
  height: auto;
  position: absolute;
  top: 50%;
  left: auto;
  transform: translate3d(0, -50%, 0) scale(0.2173913043);
}

@media only screen and (min-width: 700px) {
}

.navbar-icon-and-name-container {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: flex-start;
  /* gap: 0.3rem; */
}

.crbonlabs-icon-other {
  width: min(max(30vw, 80px), 150px);
  padding: 0rem 0;
  max-width: none;
}

.navbar-mobile-link {
  padding: 0.5rem;
  text-align: center;
  color: white;
}

.navbar-menu-icon {
  transform: scale(1.5);
  /* position: relative; */
}

.navbar-menu-icon:hover {
  color: var(--burgundy);
  cursor: pointer;
}

.close-btn-container-wrapper {
  display: flex;
  /* position: fixed; */
  width: 100%;
  /* z-index: 1000; */
  /* background: white; */
  height: 80px;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.close-btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 85%;
}

.navbar-close-icon {
  color: white;

  transform: scale(1.5);

  transform: scale(2);
}

.navbar-close-icon:hover {
  cursor: pointer;
}

/*blackout the logo*/
.crbonlabs-icon-other {
  filter: brightness(0%);
  cursor: pointer;
}

.scrolled .crbonlabs-icon:hover {
  /* filter: brightness(0%); */
  cursor: pointer;
}

@media only screen and (max-width: 1350px) {
  .navbar-links-container {
    font-size: 1rem;
  }

  .navbar-links-container > * {
    padding: 0 1.5rem;
  }
}

@media only screen and (max-width: 1000px) {
  .navbar-links-container {
    font-size: 0.8rem;
  }

  .navbar-links-container > * {
    padding: 0 1.2rem;
  }
}

@media only screen and (max-width: 900px) {
  .navbar-links-container {
    font-size: 0.9rem;
  }

  .navbar-links-container > * {
    padding: 0 1rem;
  }

  .navbar-content-container {
    padding: 0 1rem 0 1rem;
  }

  .navbar-content-container.scrolled {
    padding: 0 1rem 0 1rem;
  }
}

@media only screen and (max-height: 400px) {
  .navbar-menu-container.open {
    overflow-y: scroll;
  }
}

.navbar-menu-container.open::-webkit-scrollbar {
  width: 15px;
}

.navbar-menu-container.open::-webkit-scrollbar-track {
  background-color: transparent;
}

.navbar-menu-container.open::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 25px;
  border: 6px solid transparent;
}

.navbar-menu-container.open::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;
}

@media (min-width: 75em) {
  .header__logo {
    margin: 0;
  }
}

.header__logo {
  flex-basis: 0;
  transition: flex-basis 1s cubic-bezier(0.16, 1.08, 0.38, 0.98),
    margin 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  will-change: flex-basis, margin;
}

.header__logo {
  flex: 0 0 8.8em;
  margin: 0 auto 0 0;
  position: relative;
  z-index: 1;
}

.header__logo,
.header__nav,
.header__social-links {
  pointer-events: all;
}

.header__logo img {
  height: auto;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  width: 100%;
}

.header__logo img {
  left: 0;
  position: absolute;
  top: 50%;
  transform-origin: center left;
  width: 8.8em;
  will-change: transform;
  /* padding-top: 1rem; */
}

.header__logo img:not(.scrolled) {
  transform: translate3d(0, 8vw, 0) scale(1);
  transition: transform 1s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  width: 40.48em;
  max-width: 90vw;
}
.header__logo .scrolled {
  transform: translate3d(0, -50%, 0) scale(0.2173913043);
  transition: transform 1s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  /* padding-top: 4rem; */
  width: min(90vw, 650px);
  max-width: none;
}

@media (max-width: 620px) {
  .header__logo {
    padding-top: 0.5rem;
  }
}
