.wells__container {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.well__inputs {
  margin-right: 5px;
}

.file__button {
  border-radius: 10px;
  height: 2.5rem;
  width: 10rem;
}

.project__submit-button {
  width: 25rem;
  align-self: center;
}

.file-table,
.media-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.media-table {
  margin-bottom: 2rem;
}

.file-table th,
.file-table td,
.media-table th,
.media-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.file-table th,
.media-table th {
  background-color: #f4f4f4;
  text-align: left;
}

@media (max-width: 768px) {
  .wells__container {
    flex-direction: column;
  }
  .file__button {
    width: 100%;
  }
  .project__submit-button {
    width: 100%;
  }
}
