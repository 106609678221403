.verification__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: "Glacial-Indifference-regular";
  width: 100vw;
}

.verification-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;
}
.verification-header__title {
  font-size: 3rem;
  font-family: "Glacial-Indifference-Bold";
}

.verification-header__subtitle {
  font-size: 1rem;
  margin-top: 2%;
  margin-bottom: 1%;
}

.verification__form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
}

.verification-form__wrapper {
  margin-top: 2.5%;
  width: 100%;
}

.verification__inputs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.verification__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 20rem;
}

.verification__message {
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 2.5%;
}

.verification-error {
  color: red;
  font-size: 1rem;
  margin-top: 1rem;
  text-align: center;
  min-height: 2rem; /* Ensures the space is reserved even if no error message is shown */
  visibility: hidden; /* Hides the error text when there is no error */
}

.verification-error.active {
  visibility: visible; /* Makes the error text visible when an error is present */
}
.verification__btn-resend {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
}

.verification__btn-resend:focus {
  outline: none;
}

.verification__btn-resend:hover {
  text-decoration: none;
  color: #007bff;
}

.text-field {
  width: 8rem;
  min-width: 60px !important;
}

.text-field .MuiOutlinedInput-root {
  border-radius: 15px;
  margin: 0.5rem;
}

.text-field .MuiOutlinedInput-input {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  height: 5rem;
}

@media (max-width: 768px) {
  .verification-header__title {
    font-size: 2.5rem;
  }
  .text-field .MuiOutlinedInput-input {
    font-size: 1.5rem;
    height: 4rem;
  }
}

@media (max-width: 480px) {
  .verification-header__title {
    font-size: 2rem;
  }

  .text-field .MuiOutlinedInput-input {
    font-size: 1rem;
    height: 3rem;
  }
  .text-field .MuiOutlinedInput-root {
    margin: 0.25rem;
  }
}
