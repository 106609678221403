.modal__container-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-family: "Glacial-Indifference-Regular";
}

.modal__header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 2rem;
}

.modal__header h1 {
  margin: 0;
}

.modal__header h2 {
  color: #999999;
  font-size: 1rem;
  margin: 0;
}

.modal__actionBtns {
  position: absolute;
  bottom: 16px;
  right: 16px;
  display: flex;
  gap: 8px;
}

.modal__btn {
  border-radius: 10px;
  font-size: 0.9rem;
  text-transform: uppercase;
  padding: 0.75rem 1.25rem;
  letter-spacing: 2px;
  text-align: center;
  transition: background-color 0.25s ease-in-out;
  border-color: var(--burgundy);
  border-width: 2px;
  font-family: "Glacial-Indifference-Regular";
  color: var(--burgundy);
  background: white;
  border-style: solid;
  margin-right: auto;
}

.modal__btn:hover {
  background: var(--burgundy);
  color: white;
}
