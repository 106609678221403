/* Footer */
.footer-container {
  background: var(--dark-burgundy);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 30px 30px 0 0;
}

.footer-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0 1rem 0;
}

.footer-icons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
}

.footer-icons-container > * {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  border-radius: 50%;
}

.footer-icon-container > * {
  transform: scale(1.3);
}

.xicon-img {
  height: 18px;
  filter: brightness(0%) invert(1);
}

.footer-copyright-text {
  text-align: center;
  color: white;
  font-family: "Glacial-Indifference-Regular";
  font-size: 16px;
  margin: 0.5rem;
}

.footer-icons {
  transform: scale(1.3);
  color: var(--dark-burgundy);
}

.footer-icon-container > :hover {
  scale: 1.1;
  transition: color 0.1s;
  cursor: pointer;
}

.footer-icons:hover {
  cursor: pointer;
}

.footer-link {
  color: white;
  font-family: "Glacial-Indifference-Regular";
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.footer-link:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 700px) {
}

@media only screen and (max-height: 400px) {
}

@media (max-width: 620px) {
}
