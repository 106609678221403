/* Mission */
.mission-heading-secondary {
  color: black;
  font-family: "Glacial-Indifference-Bold";
  font-size: var(--header-font-size-d);
  text-transform: uppercase;
}
.mission-container {
  height: 100vh;
  max-height: 1300px;
  min-height: 1050px;
  background-image: url("../../images/missionBackground.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 50%;
  position: relative;
  z-index: 1;
  margin-top: -3rem;
}

.mission-header-container {
  margin-top: 3rem;
}

.mission-text-container {
  position: relative;
  z-index: 3; /* Keep text on top */
  width: 100%;
}

.mission-text-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(255, 158, 166, 0.3); */
  background-color: rgba(255, 255, 255, 0.35);
  filter: blur(15px); /* Apply the blur */
  inset: -20px; /* Expands the blur outward */
  z-index: -1; /* Place the blur behind the text */
}

.mission-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 2rem;
}
.mission-top-container {
  display: flex;
  justify-content: flex-start;
  font-family: "Glacial-Indifference-Regular";
  opacity: 0;
}
.mission-top-container.fadeLeft {
  animation: fadeLeft 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.mission-top-container.animation-done {
  animation: none !important;
  opacity: 1;
}
.mission-padding {
  padding: 1rem 2rem;
}

.mission-bottom-container {
  display: flex;
  justify-content: flex-start;
  /* margin-bottom: 1rem; */
  margin-top: -3rem;
  font-family: "Glacial-Indifference-Regular";
  /* height: 15rem; */
}
/* .mission-bottom-container.fadeRight {
    animation: fadeRight 1.25s cubic-bezier(.175, .885, .32, 1.275) forwards;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-play-state: running;
}
.mission-bottom-container.animation-done {
    animation: none !important;
    opacity: 1;
} */
.mission-h3 {
  font-family: "Glacial-Indifference-regular";
  /* text-transform: ; */
  font-size: 26px;
  letter-spacing: 2px;
  font-weight: bolder;
}
.meaningful {
  font-style: italic;
  /* font-weight: 900; */
  font-size: 27px;
}
.solutions {
  font-style: italic;
  /* font-weight: 900; */
  font-size: 30px;
}
.mission-text-primary-container {
  color: black;
  margin-top: 1.5rem;
  max-width: 45%;
  text-align: left;
}

.mission-text-secondary-container {
  color: white;
  max-width: 60%;
  text-align: left;
}

.mission-text-secondary {
  color: white;
}
.mission-text {
  font-family: "Glacial-Indifference-Regular";
  font-size: 18px;
}
.bold-span {
  font-weight: bold;
}

@media only screen and (min-width: 2250px) {
  .mission-container {
    max-height: 1300px;
    height: 100vh;
    min-height: 800px;
  }
}
@media only screen and (max-width: 1440px) {
  .mission-text-primary-container {
    color: black;
    max-width: 60%;
    margin-top: 0;
  }
}

@media only screen and (max-width: 1700px) {
  .mission-container {
    min-height: max-content;
    height: unset;
    max-height: 1000px;
  }

  .mission-bottom-container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    font-family: "Glacial-Indifference-Regular";
    height: 15rem;
  }

  .mission-bottom-paragraph {
    color: white;
    font-size: 21px;

    font-weight: bold;
  }

  .mission-bottom-paragraph .bold-span {
    font-weight: unset;
  }
}

@media only screen and (max-width: 900px) {
  .mission-text-primary-container {
    max-width: 80%;
  }
  .mission-text-secondary-container {
    max-width: 70%;
  }
  .mission-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 700px) {
  .mission-page {
    padding: 0;
  }
  .mission-text-primary-container,
  .mission-text-secondary-container {
    text-align: center;
    max-width: 100%;
  }
  .mission-h3 {
    font-family: "Glacial-Indifference-regular";
    /* text-transform: ; */
    font-size: 24px;
    letter-spacing: 2px;
    font-weight: bolder;
  }
  .meaningful {
    font-style: italic;
    /* font-weight: 900; */
    font-size: 25px;
  }
  .mission-text {
    font-size: 16px;
  }

  .mission-bottom-paragraph {
    font-size: 19px;
  }

  .mission-top-container,
  .mission-bottom-container {
    justify-content: center;
  }
  .mission-heading-secondary {
    font-size: var(--header-font-size-m);
  }
}
@media only screen and (max-height: 400px) {
  .mission-text-primary-container,
  .mission-text-secondary-container {
    text-align: center;
    max-width: 100%;
  }
}
@media (max-width: 700px) {
  .mission-container {
    /* min-height: 115vh; */
    height: auto;
    min-height: fit-content;
    max-height: none;
  }
}
.mission-container {
  border-radius: 0 0 30px 30px;
}
.mission-page {
  margin-top: 30px;
}
