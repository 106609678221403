.register__container {
  font-family: "Glacial-Indifference-regular";
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  touch-action: auto;
  width: 100vw;
}

.register__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 700px;
}
