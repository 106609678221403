.login__container {
  font-family: "Glacial-Indifference-regular";
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  touch-action: auto;
  overflow-y: hidden;
}

.login__gradient {
  width: 50vw;
  height: 90vh;
  margin-top: 10vh;
  border-radius: 0 30px 30px 0;
  background: linear-gradient(
    to bottom right,
    var(--pink),
    var(--salmon),
    var(--burgundy),
    var(--dark-burgundy)
  );
}

.gradient__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 1rem 5rem;
  text-align: center;
}

.gradient__title {
  font-family: "Glacial-Indifference-Bold";
  font-size: 3rem;
}

.gradient__subtitle {
  font-size: 1.5rem;
}

.gradient__subtitle-small {
  margin-bottom: 0;
  font-size: 1.2rem;
}

.gradient__container-info {
  display: flex;
  align-items: center;
  width: 50%;
}

.gradient__container-text {
  display: flex;
  flex-direction: column;
}

.gradient__container-title {
  font-family: "Glacial-Indifference-Bold";
  font-size: 1.3rem;
}

.gradient__container-subtitle {
  font-size: 1rem;
}

.contact__button {
  background-color: transparent;
  border-radius: 30px;
  border: solid 1px white;
  height: 3rem;
  width: 12rem;
  color: white;
  margin-top: 1rem;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.contact__button:hover {
  color: #232323;
  background-color: white;
}

.auth__container {
  display: flex;
  align-content: center;

  width: 50vw;
  height: 100vh;
}

.auth__header {
  text-align: center;
  font-family: "Glacial-Indifference-Bold";
  font-size: 3rem;
  margin: 0;
}

.auth__subheader {
  font-size: 1rem;
  color: #999999;
}

.auth__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
  max-width: 800px;
  margin-left: 5rem;
}

.auth__input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.auth__input-label {
  color: #999999;
  margin-bottom: 0.25rem;
}

.auth__button {
  width: 100%;
  height: 2.5rem;
  border-radius: 10px;
  background-color: #ff7a7f;
  border: solid 1px #ff7a7f;
  color: white;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  margin-bottom: 1rem;
  cursor: pointer;
}

.auth__button:hover {
  color: #232323;
  background-color: transparent;
}

.arrow__icon {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  animation: bounce 1.5s infinite;
  color: var(--burgundy);
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}

@media (max-width: 1024px) {
  .gradient__title {
    font-size: 2.5rem;
  }

  .gradient__subtitle {
    font-size: 1.2rem;
  }

  .gradient__subtitle-small {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .login__container {
    flex-direction: column-reverse;
  }
  .login__gradient {
    width: 100vw;
    height: fit-content;
    border-radius: 30px 30px 0 0;
  }
  .auth__container {
    width: 100vw;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .auth__header {
    font-size: 2.2rem;
  }
  .auth__form {
    width: 90%;
    margin-left: 0;
    align-items: center;
  }
  .gradient__title {
    font-size: 3rem;
  }

  .gradient__subtitle {
    font-size: 1.5rem;
  }

  .gradient__subtitle-small {
    font-size: 1.2rem;
  }
}

@media (max-width: 425px) {
  .gradient__content {
    margin: 1rem 2rem;
  }

  .gradient__title {
    font-size: 2rem;
  }

  .gradient__subtitle {
    font-size: 1.1rem;
  }

  .gradient__subtitle-small {
    font-size: 0.9rem;
  }
}
