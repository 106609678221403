.forgotPassword__container {
  font-family: "Glacial-Indifference-regular";
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  touch-action: auto;
  width: 100vw;
}
.forgotPassword__stage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.forgotPassword__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.forgot-icon__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.forgot-icon__container-grey {
  background-color: rgba(153, 153, 153, 0.2);
}

.forgot-icon__container-green {
  background-color: rgba(209, 250, 222, 0.6);
}

.forgotPassword__form {
  margin-top: 1rem;
  width: 100%;
  max-width: 25rem;
}

.forgotPassword__form-stage2 {
  margin-top: 1rem;
  width: 100%;
  max-width: 40rem;
}

.error__container {
  min-height: 1.5rem;
  font-size: 0.875rem;
  color: red;
  margin-top: 5px;
}
